<template>
  <div
    class=""
    ref='cardPopper'
  >
    <header-back name='号卡中心'>
    </header-back>
    <div class="main top44">
      <div class="mall-tag mall-pub">

        <div
          class="mall-tag-list"
          v-for="(item,index) in tagList"
          :key='index'
        >
          <label
            class="mall-tag-item"
            :class="{'tagActive':tagIndex === index}"
            @click='tagClick(index)'
          >{{item.name}}

          </label>
        </div>
      </div>
      <div class="card-home">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :offset='offset'
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="van-clearfix">
              <ul class="home-ul">
                <li
                  v-for="(item,index) in list"
                  :key='index'
                >
                  <div
                    class="ul-item"
                    @click="detaillick(item)"
                  >
                    <img :src="item.img">
                    <div class="item-tit">
                      <span>官方首发</span> {{item.name}}
                    </div>
                    <div class="item-tit2">
                      <div class="item-tit2-l"><span class="span1">免费</span> <span class="span2">包邮</span></div>
                      <div class="item-tit2-r">
                        <van-button
                          type="primary"
                          :color="variables.textColor"
                          round
                          size="small"
                          @click.stop="itemClick(item)"
                        >立即领取</van-button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </van-list>
        </van-pull-refresh>

      </div>

      <!-- <div
        class="main-de"
        :style="{'height':height}"
      >
        <img
          v-for="(item,index) in imgList"
          :key='index'
          :src='item'
        >
        <div class="main-de-yin"></div>
        <div
          class="main-de-bottom"
          :class="{'nexClass':!isPev}"
        >
          <img
            src="../../assets/image/pev.png"
            @click="pecClick()"
          >
        </div>
      </div> -->
      <div class="main-cen">
        <van-cell title="套餐类型">
          <div
            class="reason"
            slot="right-icon"
            style="max-width:76%"
          >
            <span>{{reciverAddress}}</span>
          </div>
        </van-cell>
        <div class="address-f-m">
          <van-form
            label-align="left"
            label-width="4.8em"
          >
            <div class="address-tit"><span>实名信息</span></div>
            <van-field
              v-model="username"
              name="姓名 "
              label="姓名 "
              :border='false'
              placeholder="请输入姓名（已加密）"
              autocomplete="off"
            />
            <van-field
              v-model="userIdCard"
              name="身份证号 "
              :border='false'
              label="身份证号 "
              placeholder="请输入身份证号（已加密）"
              autocomplete="off"
            />
            <van-field
              v-model="usertel"
              type="tel"
              name="手机号码 "
              :border='false'
              label="手机号码 "
              placeholder="请输入您的手机号"
              autocomplete="off"
            />
            <div class="address-tit"><span>填写配送地址</span></div>
            <van-field
              readonly
              clickable
              name="area"
              :value="uservalue"
              label="地区"
              placeholder="省市区"
              @click="showArea = true"
            />
            <van-popup
              v-model="showArea"
              round
              position="bottom"
            >
              <van-cascader
                title="请选择所在地区"
                :options="options"
                @close="showArea = false"
                @finish="onFinish"
              />
            </van-popup>
            <van-cell title="">
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #title>
                <span class="custom-title">详细</span>

              </template>
              <template #right-icon>
                <van-popover
                  v-model="showPopover"
                  :actions="searchResult"
                  @select="onSelect"
                  style="width:70% "
                >
                  <template #reference>
                    <van-field
                      v-model="userdetail"
                      label=""
                      rows="2"
                      autosize
                      clearable
                      type="textarea"
                      id='textareasss'
                      placeholder="街道门牌/楼层房间号等信息"
                      style="padding:0"
                      @input='onDetailInput'
                    />
                  </template>
                </van-popover>
              </template>
            </van-cell>

          </van-form>
        </div>
        <div class="contrain-footer">
          <p class="title">*请填写真实身份信息，未满16岁、一证五号、信息错误将无法 发货。</p>
          <van-button
            block
            type="info"
            @click="submitClik()"
          >
            提交
          </van-button>
        </div>
      </div>

    </div>
    <div id="allmap"></div>
  </div>
</template>
<script>
import AreaList from "@/utils/area";
import API from "../../api/http";
import area from "@/utils/pcas-code.json";
import HeaderBack from "../../components/header-back";
import validateRule from "@/utils/validateRule";
import variables from "@/styles/variables.scss";
import { Toast } from "vant";
const cardList = [
  // {
  //   img: require("../../assets/image/yidong.jpg"),
  //   name: "移动 月租29元 30G专享流量",
  //   path: "/phoneCard",
  //   pid: "48646",
  // },
  {
    img: require("../../assets/image/t1.jpg"),
    name: "联通 月租29元 103GB通用流量",
    path: "/phoneCard",
    pid: "48647",
    id: "29",
  },
  {
    img: require("../../assets/image/t11.jpg"),
    name: "全国通用流量卡 月租39元 203GB通用流量",
    path: "/phoneCard",
    pid: "48648",
    id: "39",
  },
];
export default {
  components: { HeaderBack },
  data() {
    return {
      textTitle: "点击领取",
      height: 400 + "px",
      reciverAddress: "",
      username: "",
      usertel: "", //电话
      userIdCard: "",
      city: "", //市
      county: "", //区
      province: "", //省
      street: "",
      areaCode: "",
      uservalue: "",
      showArea: false, //省市区
      areaList: AreaList,
      userdetail: "",
      isPev: true,
      list: cardList,

      options: [],
      searchResult: [],
      showPopover: false,
      timerOut: null,
      checkAddressId: "",
      tagList: [
        {
          id: 0,
          name: "全部",
          type: "all",
        },
        {
          id: 1,
          name: "移动",
          type: "yidong",
        },
        {
          id: 2,
          name: "联通",
          type: "liantong",
        },
        {
          id: 3,
          name: "电信",
          type: "dianxing",
        },
      ],
      tagIndex: 0,
      pid: "",
      loading: false,
      finished: false,
      refreshing: false,
      offset: 60,
      listQuery: {
        page: 1,
        limit: 100,
      },
    };
  },
  computed: {
    variables() {
      return variables;
    },
    userId() {
      return this.$store.getters["user/userId"];
    },
  },
  watch: {},
  created() {
    this.getProvince2();
  },
  mounted() {
    document.addEventListener("mouseup", this.setHeadPosition);
  },
  methods: {
    getInit(val) {
      //   API.getProductsList(this.listQuery)
      //     .then((res) => {
      //       let data = res.data;
      this.loading = false;
      if (val === 1) {
        this.list = [];
      } else if (val === 2) {
        this.list = cardList;
      } else if (val === 3) {
        this.list = [];
        this.finished = true;
        this.refreshing = false;
      } else {
        this.list = cardList;
      }
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.recommand = [];
      this.getInit(1);
    },
    onLoad() {
      if (this.refreshing) {
        this.recommand = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getInit();
    },
    tagClick(index) {
      this.tagIndex = index;

      this.getInit(index);
    },
    detaillick(item) {
      this.$router.push({
        path: "/phoneCard",
        query: {
          id: item.pid,
        },
      });
    },
    itemClick(item) {
      this.reciverAddress = item.name;
      this.pid = item.id;
      Toast("选择成功");
    },
    pecClick() {
      this.isPev = !this.isPev;
      let imgSize = 400 + "px";
      if (!this.isPev) {
        imgSize = "auto";
      }

      this.height = imgSize;
    },
    submitClik() {
      console.log("obj", this.province);
      //提交信息
      if (!this.checkInfo()) {
        return;
      }
      this.addAddress2();
    },
    checkInfo() {
      if (!this.pid) {
        Toast("请选择兑换套餐");
        return false;
      }
      if (!this.username.trim()) {
        Toast("请输入真实姓名");
        return false;
      }
      if (!validateRule.checkChinese(this.username.trim())) {
        Toast("姓名输入格式不对");
        return false;
      }

      if (validateRule.chackRegExp(this.username.trim())) {
        Toast("姓名不能存在特殊字符");
        return false;
      }
      if (!this.usertel.trim()) {
        Toast("请输入手机号");
        return false;
      }

      if (!validateRule.checkPhoneAndTel(this.usertel.trim())) {
        //手机号格式不正确
        Toast("手机号格式不正确");
        return false;
      }
      if (!this.uservalue) {
        Toast("请选择地区");
        return false;
      }
      if (!this.userdetail) {
        Toast("请输入详细地址");
        return false;
      }
      if (!validateRule.checkChinese(this.userdetail.trim())) {
        Toast("详细地址输入格式不对");
        return false;
      }
      if (validateRule.chackRegExp(this.userdetail.trim())) {
        Toast("详细地址不能存在特殊字符");
        return false;
      }
      if (this.pid === "29") {
        if (!validateRule.checkCard(this.userIdCard)) {
          Toast("你输入的身份证格式错误");
          return;
        }
      }

      if (this.province !== "浙江省") {
        Toast("地区暂只支持浙江省内");
        return;
      }
      return true;
    },
    addAddress2() {
      const obj = {
        pid: this.pid,
        id: this.checkAddressId || 0,
        source: 2,
        city: this.city,
        county: this.county,
        province: this.province,
        name: this.username.trim(),
        tel: this.usertel.trim(),
        addressDetail: this.street + " " + this.userdetail,
        uservalue: this.uservalue,
        isDefault: false,
        areaCode: this.areaCode,
        realName: this.username,
        idcard: this.userIdCard,
      };

      API.postAddressAdd(obj).then((res) => {
        this.checkAddressId = res.data;
        Toast(res.errmsg);

        if (res.errmsg.indexOf("成功") !== -1) {
          this.setAddress();
          // setTimeout(() => {
          //   this.$router.go(0);
          // }, 3000);
        }
      });
    },
    setAddress() {
      this.checkAddressId = "";
      this.city = "";
      this.county = "";
      this.province = "";
      this.username = "";
      this.usertel = "";
      this.street = "";
      this.userdetail = "";
      this.uservalue = "";
      this.areaCode = "";
      this.userIdCard = "";
    },
    getProvince2() {
      let list = area;
      this.options = list.map((item) => {
        return {
          text: item.name,
          value: item.code,
          children: item.children.map((c) => {
            let clIst = [
              ...c.children,
              {
                name: "其他",
                code: c.code + 99,
                children: [],
              },
            ];

            return {
              text: c.name,
              value: c.code,
              children: clIst.map((m) => {
                //每个街道加一个  ‘其他的选项’
                return {
                  text: m.name,
                  value: m.code,
                  children: m.children
                    .map((t) => {
                      return {
                        text: t.name,
                        value: t.code,
                      };
                    })
                    .concat([
                      {
                        text: "其他",
                        value:
                          m.code +
                          (m.children.length + 1 > 10
                            ? m.children.length + 1
                            : 0 + m.children.length + 1),
                      },
                    ]),
                };
              }),
            };
          }),
        };
      });
    },
    onFinish({ selectedOptions }) {
      console.log(selectedOptions);
      // this.show = false;
      this.uservalue = selectedOptions.map((option) => option.text).join("/");

      this.province = selectedOptions[0].text;

      this.city = selectedOptions[1].text;
      this.county = selectedOptions[2].text;
      this.street = selectedOptions[3].text;
      this.areaCode = selectedOptions[2].value;
      this.userdetail = "";
      this.showArea = false;
    },
    onDetailInput(val) {
      if (val.trim() && this.province) {
        if (this.timerOut) {
          clearTimeout(this.timerOut);
          this.timerOut = null;
        }
        this.timerOut = setTimeout(() => {
          this.getAddress(val);
        }, 500);
      } else {
        clearTimeout(this.timerOut);
        this.timerOut = null;
        this.searchResult = [];
      }
    },
    getAddress(val) {
      API.setAddressQuery({
        city: this.city,
        keywords: val,
        userId: this.userId,
      }).then((res) => {
        console.log(res);
        this.showPopover = true;
        this.searchResult = res.data.list.map((item) => {
          return {
            text: item,
            icon: "location-o",
            className: "action-dom",
          };
        });
      });
      // var myGeo = new BMap.Geocoder();
      // let that = this;
      // myGeo.getPoint(
      //   val,
      //   function (point) {
      //     if (point) {
      //       console.log("ll", point);
      //       let map = new BMap.Map("allmap");
      //       map.centerAndZoom(new BMap.Point(point.lng, point.lat), 11);
      //       that.getSeach(map, val);
      //     }
      //   },
      //   this.province
      // );
    },
    getSeach(map, val) {
      // let map = new BMap.Map("allmap");
      // map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 11);
      // //创建地址解析器实例
      // // 将地址解析结果显示在地图上，并调整地图视野
      let that = this;
      var options = {
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          console.log(1122);
          if (local.getStatus() === BMAP_STATUS_SUCCESS) {
            that.searchResult = [];
            that.showPopover = true;
            for (var i = 0; i <= results.getCurrentNumPois(); i++) {
              let m = results.getPoi(i);
              if (m) {
                let index = m.address.indexOf(that.county) || 0;
                let len = that.county.length;
                that.searchResult.push({
                  text: m.address.substring(index + len) + " " + m.title || "",
                  icon: "location-o",
                  className: "action-dom",
                });
              }
            }
            console.log("kkk", that.searchResult);
          } else {
            Toast("您输入的地址没有解析到结果！");
          }
        },
      };
      var local = new BMap.LocalSearch(map, options);
      let name = this.city + this.county + this.street + val;
      local.search(name);
    },
    onSelect(val) {
      //选中
      this.userdetail = val.text;
    },
    setHeadPosition(e) {
      const that = this;

      if (that.$refs.cardPopper) {
        // 点击其他地方关闭 popover气泡
        if (e.target.id !== "textareasss") {
          that.showPopover = false;
        }
      }
      // 点击其他地方关闭 popover气泡
      // if (!that.$refs.datePopper.contains(e.target)) {
      //   // if (e.target.outerText === "清空") return;
      //   that.showPopover = false;
      // }
    },
  },
  destroyed() {
    document.removeEventListener("mouseup", this.setHeadPosition);
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/common.scss";
.main-cen {
  font-size: 14px;
  padding: 0 6px;
  border-radius: 12px;
  overflow: hidden;
}
.address-tit {
  font-size: 12px;
  color: #4476e5;
  padding: 8px 20px 8px 26px;
  background-color: #fff;
  position: relative;
  border-top: 1px solid rgba(112, 112, 112, 0.16);
  border-bottom: 1px solid rgba(112, 112, 112, 0.16);
  &::before {
    content: "";
    position: absolute;
    left: 16px;
    top: 13px;
    height: 8px;
    width: 2px;
    background: #4476e5;
  }
}
.main-de {
  overflow: hidden;
  position: relative;
  transition: height ease-in-out 1s;
  & > img {
    width: 100%;
  }
  .nexClass > img {
    transform: rotate(-180deg);
  }
  .main-de-yin {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../assets/image/bb.png") no-repeat center;
    background-size: 100% 100%;
    height: 60px;
  }
  .main-de-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;

    text-align: center;
    animation: trans 1.5s infinite;
  }
}
.contrain-footer {
  padding: 10px 16px 30px 16px;
  p {
    font-size: 12px;
    color: #cacaca;
    margin-bottom: 12px;
  }
}
.home-ul {
  overflow: hidden;
  padding: 10px 6px;
  font-size: 12px;
  li {
    width: 50%;
    float: left;
    padding: 0 6px;
    box-sizing: border-box;
  }
  .ul-item {
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 10px;
    & > img {
      width: 100%;
      height: 140px;
    }
    .item-tit {
      padding: 10px 10px 0 10px;
      line-height: 24px;
      color: #1c1c1c;
      & > span {
        padding: 2px 6px;
        font-size: 10px;
        color: #fff;
        background: linear-gradient(90deg, #f80d0d 0%, #ff4200 100%);
        border-radius: 4px;
      }
    }
    .item-tit2 {
      padding: 0 10px 10px 10px;
      display: flex;
      & > div {
        flex: 1;
      }
      .item-tit2-l {
        .span1 {
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: 500;
          color: #f91819;
        }
        .span2 {
          font-size: 12px;
          padding: 0px 1px;
          color: #f91819;
          border: 1px solid #f91819;
          border-radius: 2px;
        }
      }
      .item-tit2-r {
        text-align: right;
        color: #ababab;
        font-size: 10px;
        padding-top: 6px;
      }
    }
  }
}
@keyframes trans {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>